import {
  APP_NOTIFICATION_TYPES,
  EntityType,
  TICKET_PRIORITY,
  TICKET_STATUS,
  TRIAL_STATUS,
} from 'store/types';
import {
  IconDefinition,
  faArrowRightFromBracket,
  faBan,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCircleXmark,
  faClock,
  faClockRotateLeft,
  faExclamationCircle,
  faGlobe,
  faHandshake,
  faHourglassHalf,
  faHouseSignal,
  faInfoCircle,
  faMessage,
  faPeopleArrows,
  faSitemap,
  faSpinner,
  faThumbsUp,
  faTowerBroadcast,
  faTriangleExclamation,
  faUpRightAndDownLeftFromCenter,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import {
  PACKAGE_NAME,
  SUBSCRIPTION_STATE,
} from 'store/types/subscription.types';
import {
  blue,
  cyan,
  geekblue,
  gold,
  green,
  grey,
  lime,
  orange,
  purple,
  red,
} from '@ant-design/colors';

import { BadgeProps } from 'antd';
import { OLT_BRIDGE_STATUS } from 'store/types/olt.types';
import { SMS_TRANSACTION_STATUS } from 'store/types/smsTransactions.types';
import { VOIP_STATUS } from 'store/types/subscriber.types';

export const SECURITY_HEADER_NAME = 'x-platform-security-token';
export const MSECURITY_HEADER_NAME = 'm-security-token';
export const SKIPPED_CALLS_STORAGE_KEY = 'skippedCalls';

export const NUMBER_ONLY_REGEX = /^[0-9]*$/;
export const PHONE_NUMBER_REGEX = /^[0-9]{10}$/;
export const PINCODE_REGEX = /^[0-9]{6}$/;
export const ADMIN_NAME_REGEX = /^[a-zA-Z0-9\s]*$/;
export const NON_NUMBERS_REGEX = /[^0-9.]/g;
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
export const EMAIL_REGEX =
  /*eslint no-control-regex: 0*/
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const MAC_REGEX = /^([0-9A-F]{2}[:]?){5}[0-9A-F]{2}$/;
export const IPv4_REGEX =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const IPv4_CLASS_A_REGEX =
  /^(?:([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-7])\.)([0-9]{1,3}\.){2}([0-9]{1,3})$/;
export const IPv4_CLASS_B_REGEX =
  /^(?:(12[8-9]|1[3-8][0-9]|19[0-1])\.)([0-9]{1,3}\.){2}([0-9]{1,3})$/;
export const IPv4_CLASS_C_REGEX =
  /^(?:(19[2-9]|2[0-4][0-9]|25[0-5])\.)([0-9]{1,3}\.){2}([0-9]{1,3})$/;
export const IPv4_PRIVATE_REGEX =
  /(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)|(^192\.+168\.)/;
export const CIDR_IP_BLOCK_REGEX =
  /^([0-9]{1,3}\.){3}[0-9]{1,3}\/([0-9]|[1-2][0-9]|3[0-2])?$/;

// export const LANG_CODES = ['en', 'hi', 'ta', 'te', 'ml'];
export const LANG_CODES = ['en'];

export const COUNTRY_CODE = '+91';
export const OTP_LENGTH = 4;
export const NOT_APPLICABLE = 'N/A';
export const OTHER_LABEL = 'OTHERS';
export const OTHER_LABEL_VIEW = 'Others';
export const RESPONSE_TIMEOUT = 1000;

export const EntityTypeIcons: { [key in EntityType]: IconDefinition } = {
  [EntityType.PLATFORM]: faGlobe,
  [EntityType.CHANNEL_PARTNER]: faHandshake,
  [EntityType.ISP]: faTowerBroadcast,
  [EntityType.FRANCHISEE]: faHouseSignal,
  [EntityType.DISTRIBUTOR]: faSitemap,
};

export const ResellerIcon = faPeopleArrows;
export const ResellerColor = blue[6];

// To follow the same color in place of displaying as tag or icons alone.
export const EntityTagColors: {
  [key in EntityType]: {
    background: string;
    border: string;
    color: string; // Text color & icon color in tag
    iconColor: string; // Entity type Icon color in place of dropdown (Except tag).
  };
} = {
  [EntityType.PLATFORM]: {
    background: '',
    border: '',
    color: '',
    iconColor: '',
  },
  [EntityType.CHANNEL_PARTNER]: {
    background: geekblue[0],
    border: geekblue[2],
    color: geekblue[6],
    iconColor: geekblue[6],
  },
  [EntityType.ISP]: {
    background: purple[0],
    border: purple[2],
    color: purple[6],
    iconColor: purple[6],
  },
  [EntityType.DISTRIBUTOR]: {
    background: '#884EA0',
    border: '#884EA0',
    color: '#ffffff',
    iconColor: '#884EA0',
  },
  [EntityType.FRANCHISEE]: {
    background: cyan[0],
    border: cyan[2],
    color: cyan[6],
    iconColor: cyan[6],
  },
};

export const TRIAL_STATUS_ICON: {
  [key in TRIAL_STATUS]: IconDefinition;
} = {
  [TRIAL_STATUS.ACTIVE]: faCheckCircle,
  [TRIAL_STATUS.PENDING]: faClock,
  [TRIAL_STATUS.COMPLETED]: faCheckDouble,
  [TRIAL_STATUS.EXITED]: faArrowRightFromBracket,
  [TRIAL_STATUS.EXTENDED]: faUpRightAndDownLeftFromCenter,
  [TRIAL_STATUS.MOVED_TO_PROD]: faThumbsUp,
};

export const COLOR_BY_STRENGTH = {
  EXCELLENT: blue.primary,
  GOOD: green.primary,
  AVERAGE: orange.primary,
  POOR: red.primary,
};
export const COLOR_BY_PERCENT = {
  BELOW_20: '#907EA0',
  BETWEEN_20_50: '#C06C84',
  ABOVE_50: '#FF4747',
};
export const COLOR_BY_CPU_MEMORY_PERCENT = {
  BELOW_50: green.primary,
  BETWEEN_50_75: orange.primary,
  ABOVE_75: red.primary,
};
export const COLOR_BY_TRIAL_STATUS: { [key in TRIAL_STATUS]: string } = {
  [TRIAL_STATUS.PENDING]: gold[4],
  [TRIAL_STATUS.ACTIVE]: blue[3],
  [TRIAL_STATUS.COMPLETED]: lime[5],
  [TRIAL_STATUS.EXITED]: red[5],
  [TRIAL_STATUS.EXTENDED]: cyan[3],
  [TRIAL_STATUS.MOVED_TO_PROD]: green[6],
};
export const ACTION_STATUS_COLOR = {
  PENDING: 'gold',
  SUCCESS: 'green',
  FAILED: 'red',
};

export const SMS_TRANSACTION_STATUS_COLOR: {
  [key in SMS_TRANSACTION_STATUS]: string;
} = {
  [SMS_TRANSACTION_STATUS.INITIATED]: 'grey',
  [SMS_TRANSACTION_STATUS.CREATED]: '#2db7f5',
  [SMS_TRANSACTION_STATUS.CLOSED]: green[4],
  [SMS_TRANSACTION_STATUS.FAILED]: red[3],
  [SMS_TRANSACTION_STATUS.PENDING]: orange[4],
  [SMS_TRANSACTION_STATUS.INVALID]: '#bfbfbf',
};

export const COLOR_BY_IMAGE_TYPE = {
  AGENT: '#4FA095',
  OTHERS: '#E78EA9',
};

export const COLOR_BY_FREQUENCY_BAND = {
  _2GHZ: '#6A5495',
  _5GHZ: '#835151',
};

export const COLOR_BY_DATA_TRANSFER_DIRECTION = {
  UPLOAD: '#FF97C1',
  DOWNLOAD: '#A6E3E9',
};

export const COLOR_BY_RSSI = {
  EXCELLENT: green.primary || green[5],
  GOOD: blue.primary || blue[5],
  AVERAGE: orange[4],
  POOR: red.primary || red[5],
};
export const COLOR_BY_WIFI_STATUS = {
  enabled: '#94C64C',
  disabled: grey[0],
  offline: '#FF8B8B',
};

export const COLOR_BY_SUBSCRIBER_STATUS = {
  ONLINE: green[3],
  OFFLINE: red[3],
  CONFIGURED: blue[3],
  PENDING: grey[3],
};

export const COLOR_BY_ACTION_STATUS = {
  PENDING: gold[4],
  SUCCESS: green[3],
  FAILED: red[5],
};

export const COLOR_BY_OLT_ONBOARDING_TRANSACTION_STATUS = {
  PENDING: orange[3],
  COMPLETED: green[3],
  ABORTED: red[3],
  UNSUPPORTED: grey[2],
};

export const COLOR_BY_REPORT_STATUS = {
  PENDING: orange.primary,
  GENERATED: blue.primary,
  DELIVERED: green.primary,
  FAILED: red.primary,
};

export const COLOR_BY_MIGRATION_PENDING_STATUS = {
  SUCCESS: green[3],
  PENDING: orange[3],
  FAILURE: red[3],
};

export const ONBOARDING_HISTORY_STATUS_COLOR = {
  COMPLETED: 'green',
  FAILED: 'red',
  TIMED_OUT: grey[3],
  ONBOARDING_COMPLETE: green[7],
  CANCELLED: red[3],
};

export const ONBOARDING_TRANSACTION_STATUS_COLOR = {
  NOT_STARTED: '',
  INPROGRESS: blue[3],
  SUCCESS: 'green',
  FAILED: 'red',
  ABORTED: red[4],
  TIMED_OUT: grey[3],
};

export const ONBOARDING_TRANSACTION_STATUS_ICON = {
  INPROGRESS: faSpinner,
  SUCCESS: faCheck,
  FAILED: faCircleXmark,
  ABORTED: faBan,
  TIMED_OUT: faClockRotateLeft,
};

export const PACKAGE_COLORS: {
  [key in PACKAGE_NAME]: string;
} = {
  SILVER: grey[0],
  GOLD: gold[5],
  PLATINUM: grey[4],
};

export const COLOR_BY_SUBSCRIPTION_STATE: {
  [key in SUBSCRIPTION_STATE]: string;
} = {
  PENDING: grey[1],
  ACTIVE: blue[3],
  SUSPENDED: orange[3],
  EXPIRED: red[4],
  UNASSIGNED: '',
};

export const OFFLINE_REASON_CODE_COLOR = {
  POWERED_OFF: blue[3],
  LOS_LINK_DOWN: red[4],
  LOS: red[3],
  UNKNOWN: '#bfbfbf',
};

export const MANAGEMENT_PROTOCOL_COLOR = {
  AGENT: '#85a5ff',
  CWMP: '#b37feb',
  OMCI: '#ff85c0',
};

export const VOIP_STATUS_TAG_COLOR = {
  ENABLED: 'green',
  DISABLED: 'default', // Antd Tag default color
};

export const VOIP_STATUS_COLOR: {
  [key in VOIP_STATUS]: string;
} = {
  Registering: red[4],
  Registered: green[5],
  Ring: blue[4],
  'Call Connected': blue[4],
};

export const PORTS_STATUS_COLOR = {
  ACTIVE: green.primary,
  DISABLED: grey[0],
  DISCONNECTED: undefined,
};

export const COLOR_BY_OLT_PORT_STATUS = {
  UP: green[4],
  DOWN: red[3],
};

export const COLOR_BY_OLT_DEVICE_OFFLINE_STATUS = {
  POWERED_OFF: blue[5],
  LINK_FAULT: red[4],
  LOS: red[3],
  UNKNOWN: red[2],
};

export const MAX_TRANSACTION_RETRY_COUNT = 11;
export const DEFAULT_RESULT_POLL_INTERVAL = 30000;
export const DEFAULT_PERIODIC_INTERVAL = 60000;
export const PROMPT_SYMBOL = '>_';
export const DEFAULT_IP = 'google.com';

export const dashboardPanelGutter: [number, number] = [5, 5];

export const CHART_COLOR_PALETTE = [
  '#66BFBF',
  '#610C63',
  '#51557E',
  '#A25B5B',
  '#9A86A4',
  '#68A7AD',
  '#9FB4FF',
  '#F10086',
  '#E45826',
  '#FFC900',
  '#8B9A46',
  '#DB6B97',
  '#105652',
  '#512D6D',
  '#005F99',
  '#E93B81',
  '#93329E',
  '#54E346',
  '#54E346',
];

export const TICKET_CATEGORY_LIST = [
  { key: 1, label: 'No Internet', value: 'No Internet' },
  { key: 2, label: 'Slow Internet', value: 'Slow Internet' },
  { key: 3, label: 'Site Issue', value: 'Site Issue' },
  { key: 4, label: 'Parental Control', value: 'Parental Control' },
  { key: 5, label: 'Pause Play', value: 'Pause Play' },
  { key: 6, label: 'Others', value: 'Others' },
];

export const TICKET_STATUS_COLORS = {
  [TICKET_STATUS.NEW]: blue[4],
  [TICKET_STATUS.IN_PROGRESS]: orange[4],
  [TICKET_STATUS.RESOLVED]: green[4],
  [TICKET_STATUS.REJECTED]: red[8],
};

export const TICKET_PRIORITY_COLORS = {
  [TICKET_PRIORITY.LOW]: blue[3],
  [TICKET_PRIORITY.MEDIUM]: orange[3],
  [TICKET_PRIORITY.HIGH]: red[3],
};

export const RSSI_STROKE_WIDTH_BY_BAND = {
  _5GHZ: 3,
  _2GHZ: 1,
};

export const COLOR_BY_TAG = {
  FRIENDLY: 'blue',
  TRIAL: 'cyan',
};

export const APP_NOTIFICATION_COLORS: {
  [key in APP_NOTIFICATION_TYPES]: string;
} = {
  [APP_NOTIFICATION_TYPES.ERROR]: red[5],
  [APP_NOTIFICATION_TYPES.WARNING]: orange[5],
  [APP_NOTIFICATION_TYPES.INFO]: blue[5],
  [APP_NOTIFICATION_TYPES.SUCCESS]: green[5],
  [APP_NOTIFICATION_TYPES.SYSTEM_MESSAGES]: grey[1],
  [APP_NOTIFICATION_TYPES.MAINTENANCE]: grey[3],
  [APP_NOTIFICATION_TYPES.UPDATE_FIRMWARE]: '#FFAB00',
};

export const APP_NOTIFICATION_ICONS: {
  [key in APP_NOTIFICATION_TYPES]: IconDefinition;
} = {
  [APP_NOTIFICATION_TYPES.ERROR]: faExclamationCircle,
  [APP_NOTIFICATION_TYPES.WARNING]: faTriangleExclamation,
  [APP_NOTIFICATION_TYPES.INFO]: faInfoCircle,
  [APP_NOTIFICATION_TYPES.SUCCESS]: faCheckCircle,
  [APP_NOTIFICATION_TYPES.SYSTEM_MESSAGES]: faMessage,
  [APP_NOTIFICATION_TYPES.MAINTENANCE]: faWarning,
  [APP_NOTIFICATION_TYPES.UPDATE_FIRMWARE]: faWarning,
};

export const WAN_PROVISIONING_STATUS_COLOR = {
  INITIATED: blue[3],
  UNSUPPORTED: grey[1],
  FAILED: red[4],
  CONFIGURED: green[4],
  PENDING: gold[4],
};

export const WAN_PROVISIONING_STATUS_ICON = {
  INITIATED: faSpinner,
  CONFIGURED: faCheck,
  FAILED: faCircleXmark,
  UNSUPPORTED: faBan,
  PENDING: faHourglassHalf,
};

export const OLT_BRIDGE_BADGE_STATUS: {
  [key in OLT_BRIDGE_STATUS]: BadgeProps['status'];
} = {
  NOT_REGISTERED: 'default',
  INIT: 'warning',
  DOWN: 'error',
  UP: 'success',
};
export const FIRMWARE_UPDATE_WARNING_COLOR = 'orange';

export const DIAG_CLIENT_ID = 'fii-diag-app';
export const VIZ_CLIENT_ID = 'fii-viz-app';
export const TERMS_AND_CONDITIONS = 'https://www.fiianalytics.com/terms-of-use';
export const PRIVACY_POLICY = 'https://www.fiianalytics.com/privacy-policy';
export const REFUND_CANCELLATION_POLICY =
  'https://www.fiianalytics.com/refund-and-cancellation-policy';

export const APK_DOWNLOAD_LINK = process.env?.APK_DOWNLOAD_LINK;
